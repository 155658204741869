import React from 'react';
import styled from 'styled-components';
import Container from './container';
import { Box } from './UIKit/system';
import Title from './title';
import Alert from '../images/svg/alert.svg';

const ContentContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli, 'Helvetica Neue', Helvetica, sans-serif, Arial;
`;

const BoxContainer = styled.div`
  border-radius: 5px;
  background-color: #f1f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px 15px 20px;
  max-width: 375px;
  border-width: 0px;
  border-left-width: 7px;
  border-style: solid;
  border-color: #f33950;
`;

const TextContainer = styled.div``;

const AlertTitle = styled(Title)`
  color: #f33950;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 1px;
  display: flex;
`;

const AlertText = styled.div`
  color: #484848;
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: 2px;
`;

const AlertContainerOuter = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlertContainer = styled.div`
  height: 32px;
  width: 32px;
  margin-right: 20px;
`;

function NoContentMessage() {
  return (
    <ContentContainer>
      <BoxContainer>
        <AlertContainerOuter>
          <AlertContainer>
            <Alert fill={'#f33950'} />
          </AlertContainer>
        </AlertContainerOuter>
        <TextContainer>
          <AlertTitle>No Content</AlertTitle>
          <AlertText>
            Please add some blocks to this page and click save to get started.
          </AlertText>
        </TextContainer>
      </BoxContainer>
    </ContentContainer>
  );
}

export default NoContentMessage;
